<template>
  <default>
     <v-chip-group mandatory active-class=" primary">
            <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard
            </v-chip> <span class="mt-2 mx-2"> > </span>
           <v-chip active-class="false"  :to="{ name: 'Products' }">Products
            </v-chip> <span class="mt-2 mx-2"> > </span>
           <v-chip :to="{ name: 'AkibaPlan' }">
            {{ $options.name }}
            </v-chip>
        </v-chip-group>
    <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-2 md:tw-mt-10">
      <card-title icon="/images/icons/top-icon.png">Whole Life Plan Calculator</card-title>
      <div class="tw-w-full tw-flex tw-flex-wrap tw-py-3 md:tw-py-6">
        <keep-alive>
          <component
          :is="current"
          :productData="productData"
          :benefits="benefits"
          :productOptions="computedProductOptions"
          :terms="terms"
          :quoteSummaryData="quoteSummaryData"
          @to-quote-summary="toQuoteSummary"
          @fetchBenefits="fetchBenefits"
        ></component>
        </keep-alive>
      </div>
    </v-card>
  </default>
</template>

<script>
import Default from '../../layouts/Default';
import CardTitle from '../../components/CardTitle';
import BackButton from '../../components/BackButton';
import QuoteSummary from '../../components/rfq/QuoteSummary';
import ClientQuotation from '../../components/rfq/akiba-halisi/Quote';
import ProductMixin from '@/mixins/ProductMixin';
import savedQuoteMixin from '@/mixins/savedQuoteMixin';

export default {
  name: 'WholeLifePlan',
  metaInfo: {
    title: 'RFQ - Whole Life Plan',
  },
  components: {
    QuoteSummary, BackButton, CardTitle, Default, ClientQuotation,
  },
  mixins: [ProductMixin, savedQuoteMixin],
  data() {
    return {
      current: ClientQuotation,
      productData: { prodCode: 2021200 },
      benefits: [],
      productOptions: [],
      terms: [],
      quoteSummaryData: {},
      termLimit: {
        min: 5,
        max: 100,
      },
    };
  },
  mounted() {
    this.fetchProductOptions();
    this.fetchBenefits();
    this.fetchTerms(this.scanObject(this.productOptions, '0.optionCode', 2021217));
    this.checkSavedQuote('akiba');
  },
  methods: {
    fetchTerms(optionCode) {
      const productOption = this.productOptions.find((option) => option.optionCode === optionCode);
      let optionMinTerm = this.scanObject(productOption, 'optionMinTerm', 5);
      const optionMaxTerm = this.scanObject(productOption, 'optionMaxTerm', 100);
      const terms = [];
      while (optionMinTerm <= optionMaxTerm) {
        // eslint-disable-next-line no-plusplus
        terms.push(optionMinTerm++);
      }
      this.terms = terms;
    },
  },
};
</script>
